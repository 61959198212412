import React from "react";
import Seo from "../components/seo";
import Default from "../components/default";
import { Button } from "../components/templates/buttons";
import LogoIcon from "../components/icons/logoIcon";
import Breadcrumbs from "../components/breadcrumbs";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <>
      <Seo title="Page Not Found" />
      <Default>
        <section className="notfound">
          <Breadcrumbs>
            <span>Page Not Found</span>
          </Breadcrumbs>
          <LogoIcon />
          <h1 className="section__title">Page not found</h1>
          <h2>
            Hmm... we couldn't find that page.
            <br />
            Did you make a typo?
          </h2>
          <Button text="Go Home" type="notfound" link="/" />
        </section>
      </Default>
    </>
  );
};

export default NotFoundPage;
